
  article {
    width: 80vw;
    display: flex;
    flex-direction: column;
  }
  
  h2, h3 { align-self: center; }

  p{
    line-height: 1.5;
  }
  
  .text-align-justify{
     text-align:justify;
  }
  
  .text-align-left{
    text-align: left;
  }


.light-bg {
  background-color: #f8f8f8;
}
.dark-bg {
  background-color: #434343;
}
.overlay-light,
overlay-light .YTPOverlay {
  position: relative;
}
.overlay-light:before,
overlay-light .YTPOverlay:before {
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  background: rgba(255, 255, 255, 0.5);
}
.overlay-dark,
.overlay-dark .YTPOverlay {
  position: relative;
}
.overlay-dark:before,
.overlay-dark .YTPOverlay:before {
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
}
.rs-video-overlay .rs-fullvideo-cover {
  background: rgba(0, 0, 0, 0.3);
}
.image-bg {
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-size: cover;
}
.parallax-bg {
  position: relative;
  background-attachment: fixed;
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-size: cover;
}
.fixed-height-large {
  min-height: 800px;
}
.fixed-height-medium {
  min-height: 700px;
}
.fixed-height-small {
  min-height: 600px;
}
.vertical-section {
  position: relative;
  display: table;
  width: 100%;
  height: 100%;
  text-align: center;
}
.vertical-content {
  display: table-cell;
  height: 100%;
  vertical-align: middle;
}
@-webkit-keyframes scroll-down-anim {
  0% {
    bottom: 2px;
  }
  50% {
    bottom: 7px;
  }
  100% {
    bottom: 2px;
  }
}
@keyframes scroll-down-anim {
  0% {
    bottom: 2px;
  }
  50% {
    bottom: 7px;
  }
  100% {
    bottom: 2px;
  }
}
.scroll-down {
  width: 40px;
  height: 40px;
  margin-left: -20px;
  position: absolute;
  bottom: 20px;
  left: 50%;
  text-align: center;
  -webkit-transition: all 500ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -o-transition: all 500ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: all 500ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.scroll-down:before {
  display: block;
  content: "";
  width: 20px;
  height: 20px;
  margin: 0 0 0 -10px;
  position: absolute;
  bottom: 0;
  left: 50%;
  border: 1px solid #fff;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 500ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -o-transition: all 500ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: all 500ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  z-index: 1;
}
.scroll-down:hover:before {
  background: #ed5f5e;
  border: 1px solid #ed5f5e;
}
.scroll-down .scroll-down-icon {
  display: block;
  width: 10px;
  height: 10px;
  margin-left: -5px;
  position: absolute;
  left: 50%;
  bottom: 4px;
  color: #fff;
  font-size: 15px;
  -webkit-animation: scroll-down-anim 1s infinite;
  animation: scroll-down-anim 1s infinite;
  z-index: 2;
}
#back-to-top {
  position: fixed;
  bottom: 20px;
  right: 40px;
  z-index: 9999;
  text-align: center;
  cursor: pointer;
  border: 0;
  text-decoration: none;
  opacity: 0;
}
#back-to-top i {
  width: 32px;
  height: 32px;
  line-height: 32px;
  color: #fff;
  font-size: 20px;
  background: #ed5f5e;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
#back-to-top i:hover {
  background: #e51b1a;
}
#back-to-top.show {
  opacity: 1;
}
/* clear the first in row for any block that has the class "multi-columns-row" */
.multi-columns-row .col-xs-6:nth-child(2n + 3) {
  clear: left;
}
.multi-columns-row .col-xs-4:nth-child(3n + 4) {
  clear: left;
}
.multi-columns-row .col-xs-3:nth-child(4n + 5) {
  clear: left;
}
.multi-columns-row .col-xs-2:nth-child(6n + 7) {
  clear: left;
}
.multi-columns-row .col-xs-1:nth-child(12n + 13) {
  clear: left;
}
@media (min-width: 768px) {
  /* reset previous grid */
  .multi-columns-row .col-xs-6:nth-child(2n + 3) {
    clear: none;
  }
  .multi-columns-row .col-xs-4:nth-child(3n + 4) {
    clear: none;
  }
  .multi-columns-row .col-xs-3:nth-child(4n + 5) {
    clear: none;
  }
  .multi-columns-row .col-xs-2:nth-child(6n + 7) {
    clear: none;
  }
  .multi-columns-row .col-xs-1:nth-child(12n + 13) {
    clear: none;
  }
  /* clear first in row for small columns */
  .multi-columns-row .col-sm-6:nth-child(2n + 3) {
    clear: left;
  }
  .multi-columns-row .col-sm-4:nth-child(3n + 4) {
    clear: left;
  }
  .multi-columns-row .col-sm-3:nth-child(4n + 5) {
    clear: left;
  }
  .multi-columns-row .col-sm-2:nth-child(6n + 7) {
    clear: left;
  }
  .multi-columns-row .col-sm-1:nth-child(12n + 13) {
    clear: left;
  }
}
@media (min-width: 992px) {
  /* reset previous grid */
  .multi-columns-row .col-sm-6:nth-child(2n + 3) {
    clear: none;
  }
  .multi-columns-row .col-sm-4:nth-child(3n + 4) {
    clear: none;
  }
  .multi-columns-row .col-sm-3:nth-child(4n + 5) {
    clear: none;
  }
  .multi-columns-row .col-sm-2:nth-child(6n + 7) {
    clear: none;
  }
  .multi-columns-row .col-sm-1:nth-child(12n + 13) {
    clear: none;
  }
  /* clear first in row for medium columns */
  .multi-columns-row .col-md-6:nth-child(2n + 3) {
    clear: left;
  }
  .multi-columns-row .col-md-4:nth-child(3n + 4) {
    clear: left;
  }
  .multi-columns-row .col-md-3:nth-child(4n + 5) {
    clear: left;
  }
  .multi-columns-row .col-md-2:nth-child(6n + 7) {
    clear: left;
  }
  .multi-columns-row .col-md-1:nth-child(12n + 13) {
    clear: left;
  }
}
@media (min-width: 1200px) {
  /* reset previous grid */
  .multi-columns-row .col-md-6:nth-child(2n + 3) {
    clear: none;
  }
  .multi-columns-row .col-md-4:nth-child(3n + 4) {
    clear: none;
  }
  .multi-columns-row .col-md-3:nth-child(4n + 5) {
    clear: none;
  }
  .multi-columns-row .col-md-2:nth-child(6n + 7) {
    clear: none;
  }
  .multi-columns-row .col-md-1:nth-child(12n + 13) {
    clear: none;
  }
  /* clear first in row for large columns */
  .multi-columns-row .col-lg-6:nth-child(2n + 3) {
    clear: left;
  }
  .multi-columns-row .col-lg-4:nth-child(3n + 4) {
    clear: left;
  }
  .multi-columns-row .col-lg-3:nth-child(4n + 5) {
    clear: left;
  }
  .multi-columns-row .col-lg-2:nth-child(6n + 7) {
    clear: left;
  }
  .multi-columns-row .col-lg-1:nth-child(12n + 13) {
    clear: left;
  }
}
#preloader {
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fcfcfc;
  z-index: 10000;
  position: fixed;
  display: block;
}
#preloader #spinner {
  position: relative;
  height: 95px;
  width: 95px;
  top: 45%;
  margin: 0 auto -45px;
  border-radius: 100%;
  -webkit-animation: rotation .75s infinite linear;
  -moz-animation: rotation .75s infinite linear;
  -o-animation: rotation .75s infinite linear;
  animation: rotation .75s infinite linear;
}
#preloader #spinner:before {
  content: "";
  display: block;
  position: absolute;
  left: -2px;
  top: -2px;
  height: 100%;
  width: 100%;
  border-top: 2px solid #0f0f0f;
  border-left: 2px solid transparent;
  border-bottom: 2px solid transparent;
  border-right: 2px solid transparent;
  border-radius: 100%;
}
@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
/* Slides */
.rev_slider .tp-caption.NotGeneric-Title {
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  font-weight: 700;
}
.rev_slider .tp-caption.NotGeneric-SubTitle {
  font-family: 'Lato', sans-serif;
  text-transform: uppercase;
}
.rev_slider .tp-caption.NotGeneric-Text {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
}
.uranus.tparrows {
  background: #000;
  width: 32px;
  height: 32px;
}
.uranus.tparrows:before {
  font-size: 16px;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
}
/* Index_2 */
.intro-content h1 {
  font-size: 42px;
  font-weight: 700;
}
/*--------------------------------------------------
    09. Services Section
---------------------------------------------------*/
.service {
  background-color: #f8f8f8;
  padding: 40px 30px 30px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.service-icon {
  background-color: #ed5f5e;
  color: #fff;
  width: 44px;
  height: 44px;
  text-align: center;
  font-size: 23px;
  line-height: 42px;
}
.service h4 {
  text-transform: uppercase;
  margin-top: 35px;
}
.service-text {
  margin: 10px 0;
}
.service.service-type-2 {
  background-color: transparent;
  padding: 0;
}
.service.service-type-2 .service-header h4 {
  margin-top: 0;
}
.service.service-type-2 .service-header .service-icon {
  background-color: transparent;
  margin-right: 25px;
  color: #ed5f5e;
  border: 1px solid #ed5f5e;
  width: 36px;
  height: 36px;
  font-size: 18px;
  line-height: 36px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.service.service-type-2 .service-text {
  margin: 0 0 30px 60px;
}
.service.service-type-2:hover .service-header .service-icon {
  background: #ed5f5e;
  color: #fff;
}
/*--------------------------------------------------
    11. Portflio Section
---------------------------------------------------*/
.mfp-fade.mfp-bg {
  opacity: 0;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  -webkit-transition: all 0.4s ease-out;
}
.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}
.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}
.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  -webkit-transition: all 0.4s ease-out;
}
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}
.portfolio .pf-image {
  overflow: hidden;
  position: relative;
}
.portfolio .pf-image img {
  width: 100%;
  display: block;
}
.portfolio .pf-image .overlay .overlay-caption {
  display: table;
  height: 100%;
  width: 100%;
}
.portfolio .pf-image .overlay .overlay-caption .overlay-content {
  display: table-cell;
  vertical-align: middle;
}
.pf-style .overlay {
  height: 100%;
  width: 100%;
  left: 0;
  position: absolute;
  text-align: center;
  top: -100%;
  z-index: 2;
  -webkit-transition: all 300ms;
  -o-transition: all 300ms;
  transition: all 300ms;
  overflow: hidden;
}
.pf-style .overlay-content {
  opacity: 0;
  -webkit-transition: all 300ms;
  -o-transition: all 300ms;
  transition: all 300ms;
}
.pf-style:hover .overlay {
  top: 0;
}
.pf-style:hover .overlay-content {
  opacity: 1;
  background-color: rgba(237, 95, 94, 0.9);
}
.portfolio.no-filter .pf-item {
  float: left;
}
.portfolio.gutter .pf-item {
  padding: 10px;
}
.portfolio.col-1 .pf-item {
  width: 100%;
}
.portfolio.col-2 .pf-item {
  width: 49.99%;
}
.portfolio.col-3 .pf-item {
  width: 33.3333333%;
}
.portfolio.col-4 .pf-item {
  width: 25%;
}
.pf-filter li {
  margin-bottom: 30px;
}
.pf-filter li .iso-button {
  background-color: #fff;
  color: #434343;
  padding: 10px 15px;
  margin-right: 5px;
  margin-bottom: 5px;
  text-transform: uppercase;
  -o-transition: all 0.6s;
  transition: all 0.6s;
  -webkit-transition: all 0.6s;
  font-size: 12px;
  letter-spacing: 2px;
}
.pf-filter li .iso-button:hover {
  color: #ed5f5e;
}
.pf-filter li .iso-active {
  color: #ed5f5e;
}
.pf-filter.pf-filter-gray .iso-button {
  background-color: #f8f8f8;
  color: #434343;
}
.pf-filter.pf-filter-gray .iso-button:hover {
  color: #ed5f5e;
}
.pf-filter.pf-filter-gray .iso-active {
  color: #ed5f5e;
}
@media screen and (max-width: 992px) {
  .portfolio.col-3 .pf-item,
  .portfolio.col-4 .pf-item {
    width: 49.99%;
  }
}
@media screen and (max-width: 540px) {
  .portfolio.col-2 .pf-item,
  .portfolio.col-3 .pf-item,
  .portfolio.col-4 .pf-item {
    width: 100%;
  }
}
/*--------------------------------------------------
    12. Video  Section
---------------------------------------------------*/
.video-caption h6 {
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  letter-spacing: 2px;
}
/*--------------------------------------------------
    13. Team  Section
---------------------------------------------------*/
.team-item {
  text-align: center;
}
.team-item-image {
  position: relative;
  overflow: hidden;
}
.team-item-image-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(237, 95, 94, 0.9);
  top: -100%;
  left: 0;
  -webkit-transition: all 300ms;
  -o-transition: all 300ms;
  transition: all 300ms;
}
.team-item-image img {
  width: 100%;
}
.team-item-icons {
  position: absolute;
  padding: 15px 0;
  height: 50px;
  width: 100%;
  top: 50%;
  margin-top: -25px;
  font-size: 15px;
}
.team-item-icons a {
  color: #fff;
  margin: 0 10px;
}
.team-item-icons a i {
  width: 30px;
  height: 30px;
  border: 1px solid #fff;
  font-size: 15px;
  line-height: 30px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.team-item-icons a i:hover {
  background: #fff;
  color: #ed5f5e;
}
.team-item-info {
  background: #fafafa;
  -webkit-box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
  padding: 15px 0;
}
.team-item-name {
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
}
.team-item-position {
  font-size: 11px;
  color: #aaaaaa;
}
.team-item:hover .team-item-image-overlay {
  top: 0;
}
.team-item:hover .team-item-name {
  color: #ed5f5e;
}
@media screen and (max-width: 540px) {
  #team .row [class*="col-"] {
    width: 100%;
  }
  #team .team-item-icons {
    font-size: 24px;
  }
}
@media screen and (max-width: 360px) {
  #team .team-item-icons {
    font-size: 15px;
  }
}
/*--------------------------------------------------
    14. Progress/ Our Skills
---------------------------------------------------*/
.progress-heading p {
  margin: 10px 0 0;
  font-size: 0.8em;
}
.progress {
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #f0f0f0;
}
.progress.progress-thin {
  height: 3px;
}
.progress-bar {
  background-color: #ed5f5e;
  -webkit-box-shadow: none;
  box-shadow: none;
}
/*--------------------------------------------------
    15. Accordion/ Why Choose Us
---------------------------------------------------*/
.panel {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.panel-group .panel {
  border-radius: 0;
  border: none;
}
.panel-group .panel + .panel {
  margin-top: 1px;
}
.panel-default .panel-heading {
  background: none;
  border: none;
  padding: 0;
}
.panel-title {
  font-size: 14px;
  text-transform: capitalize;
  border: none;
}
.panel-title a {
  position: relative;
  background: #ed5f5e;
  color: #fff;
  display: block;
  padding: 22px 40px;
  border-top: 1px solid #eeeeee;
  -webkit-transition: all 300ms;
  -o-transition: all 300ms;
  transition: all 300ms;
}
.panel-title a:before {
  content: '-';
  text-align: center;
  width: 28px;
  height: 28px;
  font-size: 24px;
  line-height: 26px;
  position: absolute;
  border-radius: 0;
  top: 15px;
  right: 13px;
}
.panel-title a.collapsed {
  background-color: transparent;
  color: #434343;
  border-top: none;
}
.panel-title a.collapsed:before {
  content: '+';
}
.panel-body {
  padding: 25px 40px;
}
.panel-group .panel-heading + .panel-collapse .panel-body {
  border-top: none;
}
/*--------------------------------------------------
    16. Stat
---------------------------------------------------*/
.stat-item {
  overflow: hidden;
}
.stat-item-icon {
  float: left;
  background-color: #ed5f5e;
  color: #fff;
  width: 44px;
  height: 44px;
  text-align: center;
  font-size: 23px;
  line-height: 42px;
  margin-right: 18px;
}
.stat-item-number {
  float: left;
  font-weight: 700;
  font-size: 30px;
  margin-right: 18px;
  line-height: 42px;
  min-width: 52px;
}
.stat-item-text {
  padding-top: 4px;
  padding-left: 62px;
}
/*Index 2*/
.stat-item.stat-item-type-2 {
  text-align: center;
}
.stat-item.stat-item-type-2 .stat-item-icon {
  float: none;
  margin: 0 auto;
  padding-bottom: 20px;
  background: transparent;
  border: 1px solid #fff;
  width: 36px;
  height: 36px;
  font-size: 18px;
  line-height: 36px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.stat-item.stat-item-type-2 .stat-item-number {
  float: none;
  font-size: 44px;
  margin-right: 0;
  padding-top: 30px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}
.stat-item.stat-item-type-2 .stat-item-text {
  padding-top: 10px;
  padding-left: 0;
  font-size: 24px;
}
@media screen and (max-width: 480px) {
  #stat .row [class*="col-"] {
    width: 100%;
  }
}
/*--------------------------------------------------
    17. Testimonial
---------------------------------------------------*/
.testimonial {
  position: relative;
}
.testimonial-item {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 20px;
}
.testimonial-desc {
  font-size: 1.2em;
  font-style: italic;
  letter-spacing: 1px;
}
.testimonial-thumb img {
  margin: 40px auto 0;
  width: 60px !important;
  border-radius: 50px;
  border: 2px solid #fff;
}
.testimonial-icon i {
  font-size: 42px;
  margin: 0 auto 10px;
  color: #ed5f5e;
}
.testimonial-author {
  padding: 10px 10px;
  font-family: 'Montserrat', sans-serif;
  font-size: 11px;
  letter-spacing: 1px;
}
/*--------------------------------------------------
    18. Blog
---------------------------------------------------*/
.blog-index {
  padding: 60px 0;
}
.blog {
  background-color: #f8f8f8;
}
.blog-post-info {
  border-bottom: 1px solid #e6e6e6;
  padding: 10px 25px;
  color: #999999;
  font-size: 0.8em;
  font-style: italic;
}
.blog-post-info .time {
  float: left;
}
.blog-post-info .comments {
  float: right;
}
.blog-post-info .comments a {
  color: #999999;
}
.blog-post-info .comments a:hover {
  color: #ed5f5e;
}
.blog-post-info i {
  margin-right: 5px;
}
.blog-post-body {
  padding: 15px 25px 25px;
}
.blog-post-body h4 {
  text-transform: capitalize;
}
.blog-post-body h4 a {
  color: #5d5d5d;
}
.blog-post-body h4 a:hover {
  color: #ed5f5e;
}
.blog-post-body a.read-more {
  font-weight: 700;
}
.postSingle .postTitle {
  padding-bottom: 10px;
}
.postSingle .postTitle h1 {
  font-size: 24px;
  margin-bottom: 5px;
}
.postSingle .postMeta {
  color: #808080;
  font-size: 0.9em;
  font-style: italic;
  padding: 10px 20px;
  margin-bottom: 30px;
  background: #f8f8f8;
}
.postSingle .postMeta a {
  color: #808080;
}
.postSingle .postMeta a:hover {
  color: #ed5f5e;
}
.postSingle .postMeta-info {
  float: left;
}
.postSingle .postMeta-info span {
  margin-right: 15px;
}
.postSingle .postMeta-info span i {
  margin-right: 3px;
}
.postSingle .postMeta-date {
  float: right;
}
.postSingle .postMeta-date span i {
  margin-right: 3px;
}
.postSingle .postMedia {
  padding: 0;
}
.postSingle .postTags {
  margin-top: 30px;
}
.postSingle .postTags h4 {
  font-size: 14px;
  float: left;
  margin-right: 10px;
  margin-top: 4px;
  text-transform: capitalize;
}
.postSingle .postTags h4 i {
  margin-right: 5px;
}
.postSingle .postTags ul li a {
  background: #ed5f5e;
  color: #fff;
  display: inline-block;
  padding: 5px 12px;
  font-size: 11px;
  margin-bottom: 10px;
}
.entriesContainer {
  margin-top: 40px;
}
.entriesContainer .comments-list {
  padding: 0;
  margin-top: 40px;
}
.entriesContainer .comments-list .img {
  border: 2px solid #ed5f5e;
  color: #ed5f5e;
  float: left;
  height: 35px;
  width: 35px;
  line-height: 31px;
  margin-top: 5px;
  text-align: center;
}
.entriesContainer .comments-list .commentContent {
  margin-bottom: 15px;
  margin-left: 50px;
}
.entriesContainer .comments-list .commentContent p.expert {
  font-size: 13px;
  line-height: 23px;
  margin-top: 10px;
}
.entriesContainer .comments-list .commentContent .date a {
  color: #ed5f5e;
  font-size: 11px;
  display: inline-block;
}
.comment {
  padding: 20px 20px 50px;
  position: relative;
  background: #f8f8f8;
}
.comment.children {
  margin-top: 30px;
}
.comments a {
  display: inline-block;
}
.comments .author a {
  color: #434343;
}
.comments .replys {
  margin-left: 40px;
}
.comments .replys.children {
  margin-left: 80px;
}
.comments .reply-btn {
  float: right;
}
.comments .reply-btn a {
  color: #434343;
  display: inline-block;
  font-size: 13px;
  font-weight: 600;
  padding: 8px 12px;
}
/*Respond*/
.respond .replyForm input[type="text"],
.respond .replyForm input[type="email"],
.respond .replyForm textarea {
  background: #f8f8f8;
  border: 0 none;
  color: #434343;
  font-family: inherit;
  font-size: 13px;
  margin: 0 0 28px;
  outline: 0 none;
  padding: 12px 10px;
  resize: none;
  width: 100%;
  border: 1px solid #f2f2f1;
}
.respond .replyForm #success,
.respond .replyForm #error {
  display: none;
}
/*Sidebar*/
.widget {
  margin-bottom: 20px;
  background-color: #f8f8f8;
  padding: 25px 25px 12px;
}
.widget h4 {
  margin-bottom: 20px;
}
.widget .search-form {
  position: relative;
}
.widget .search-form input[type="text"] {
  border: none;
  padding: 12px 10px;
  font-size: 13px;
  width: 100%;
  background: #f8f8f8;
  border: 0 none;
  outline: 0;
  color: #434343;
  border: 1px solid #f8f8f8;
}
.widget .search-form .submit-search {
  border: medium none;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  padding: 12px 15px;
  font-weight: 600;
  color: #fff;
  background: #434343;
}
.widget .search-form .submit-search:hover {
  background: #ed5f5e;
}
.widget .cat-list li,
.widget .cat-archives li,
.widget .meta li {
  border-bottom: 1px solid #ebebeb;
  line-height: 30px;
  padding: 5px 0;
}
.widget .cat-list li a,
.widget .cat-archives li a,
.widget .meta li a {
  color: #909090;
  font-weight: 600;
}
.widget .cat-list li a:hover,
.widget .cat-archives li a:hover,
.widget .meta li a:hover {
  color: #ed5f5e;
}
.widget .cat-list li:last-child,
.widget .cat-archives li:last-child,
.widget .meta li:last-child {
  border-bottom: none;
}
.widget .cat-list li span.countCat {
  color: #ed5f5e;
  font-size: 12px;
}
.widget .meta abbr {
  color: #ed5f5e;
}
.widget .tag-list li {
  display: inline-block;
  margin-bottom: 5px;
  margin-right: 1px;
}
.widget .tag-list li a {
  background: #eeeeee;
  color: #909090;
  font-weight: 600;
  display: inline-block;
  padding: 5px 12px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.widget .tag-list li a:hover {
  background: #ed5f5e;
  color: #fff;
}
.widget .cat-list li:last-child,
.widget .cat-archives li:last-child {
  border-bottom: medium none;
}
.widget .meta abbr {
  border-bottom: 1px dotted #434343;
  cursor: help;
}
.widget.widget-bottom {
  background-color: transparent;
}
.widget.widget-bottom h4 {
  padding-bottom: 5px;
  border-bottom: 1px solid #ebebeb;
}
.widget.widget-bottom .cat-list li,
.widget.widget-bottom .cat-archives li,
.widget.widget-bottom .meta li {
  border-bottom: 0px;
}
.widget-search {
  padding: 0;
}
.pagination {
  border-radius: 0;
  margin-top: 0;
}
.pagination li:last-child a,
.pagination li:first-child a,
.pagination li:last-child span,
.pagination li:first-child span {
  border-radius: 0;
}
.pagination li a,
.pagination li span {
  color: #ed5f5e;
}
.pagination .active span {
  background-color: #ed5f5e;
  border-color: #ed5f5e;
}
.pagination .active span:focus,
.pagination .active span:hover {
  background-color: #ed5f5e;
  border-color: #ed5f5e;
}
@media screen and (max-width: 768px) {
  .blog-index {
    padding: 30px 0;
  }
}
@media screen and (max-width: 480px) {
  .postSingle .postMeta-info,
  .postSingle .postMeta-date {
    float: none;
  }
}
/*--------------------------------------------------
    19. Client
---------------------------------------------------*/
.client-item {
  display: inline-block;
  padding-top: 16px;
  padding-bottom: 16px;
  opacity: 0.4;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.client-item:hover {
  opacity: 1;
}
.client-item img {
  max-width: 120px;
  margin: 0 auto;
}
.client-type-2 .client-item {
  padding-top: 24px;
  padding-bottom: 24px;
  border-left: 1px solid #cccccc;
}
.client-type-2 .client-item:last-child {
  border-right: 1px solid #cccccc;
}
.client-type-2 .client-item:hover {
  border-left: 1px solid rgba(204, 204, 204, 0.4);
}
.client-type-2 .client-item:hover:last-child {
  border-right: 1px solid rgba(204, 204, 204, 0.4);
}
.client-type-2 .client-item img {
  max-width: 120px;
  margin: 0 auto;
}
@media screen and (max-width: 992px) {
  .client-type-2 .client-item {
    border: 1px solid #cccccc;
  }
}
/*--------------------------------------------------
    20. Contact
---------------------------------------------------*/
.contact-form {
  padding-top: 10px;
}
.contact-form .error {
  font-size: 11px;
  color: #ff0000;
  letter-spacing: 1px;
  display: none;
}
.contact-form #ajaxsuccess {
  background: #f5f5f5;
  padding: 10px 15px;
  color: #28a745;
  display: none;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.contact-form-item {
  margin-bottom: 20px;
}
.contact-form-item input[type="text"],
.contact-form-item input[type="password"],
.contact-form-item textarea {
  background: #f5f5f5;
  border: none;
  color: #000;
  font-size: 0.9em;
  width: 100%;
  padding: 10px 15px;
}
.contact-form-item input[type="text"]:focus,
.contact-form-item input[type="password"]:focus,
.contact-form-item textarea:focus {
  outline: none;
}
.contact-form-item textarea {
  height: 160px;
}
.contact-form-item ::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #b3b3b3;
}
.contact-form-item :-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #b3b3b3;
  opacity: 1;
}
.contact-form-item ::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #b3b3b3;
  opacity: 1;
}
.contact-form-item :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #b3b3b3;
}
.contact-form-button {
  margin-bottom: 0;
}
.contact-form-dark .contact-form-item input[type="text"],
.contact-form-dark .contact-form-item input[type="password"],
.contact-form-dark .contact-form-item textarea {
  background: #5d5d5d;
  color: #fff;
}
.contact-info {
  padding-left: 30px;
}
.contact-info-icon {
  float: left;
  background-color: #ed5f5e;
  color: #fff;
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 18px;
  line-height: 40px;
  margin-right: 18px;
}
.contact-info-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 1.2em;
}
.contact-info-text {
  font-size: 0.9em;
  padding-top: 5px;
  padding-left: 58px;
}
@media screen and (max-width: 768px) {
  .contact-info {
    padding-left: 0px;
  }
}
/*--------------------------------------------------
    21. Footer
---------------------------------------------------*/
.site-footer {
  background-color: #2a2a2a;
  padding: 20px 0px;
}
.site-footer .copyright {
  color: #909090;
  margin: auto;
  margin-top: 15px;
}
.site-footer .copyright a {
  color: #a9a9a9;
}
.site-footer .copyright a:hover {
  color: #ed5f5e;
}
.site-footer .social-icon {
  padding: 5px 0px;
}
.site-footer .social-icon a i {
  width: 28px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  color: #a9a9a9;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.site-footer .social-icon a:hover i {
  background-color: #ed5f5e;
  color: #fff;
}
@media screen and (max-width: 768px) {
  .site-footer .pull-right {
    float: none !important;
  }
  .site-footer .copyright {
    display: block;
    width: 100%;
    padding-bottom: 15px;
  }
  .site-footer .social-icon {
    display: block;
    width: 100%;
  }
}

.price-item {
  background-color: #f8f8f8;
  padding-bottom: 30px;
  -webkit-box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.price-item:hover {
  -webkit-box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
}
.price-item-header {
  background-color: #2a2a2a;
  padding: 5px 20px;
}
.price-item-header h4 {
  color: #fff;
  font-weight: 600;
}
.price-item-header-alt {
  background-color: #ed5f5e;
}
.price-item-price {
  padding: 30px 20px 20px;
}
.price-item-price .big {
  font-size: 56px;
  line-height: 1em;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 0;
  color: #ed5f5e;
}
.price-item-price .big .currency {
  font-size: 40%;
  line-height: 1.7em;
  vertical-align: top;
}
.price-item-price .small {
  color: #000;
  font-weight: 600;
}
.price-item-features {
  padding: 20px 20px 0;
  text-transform: uppercase;
}
.price-item-features li {
  border-bottom: 1px dotted #dcdcdc;
  padding: 12px 0;
}
@media screen and (max-width: 768px) {
  .price-item:hover {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}
/*--------------------------------------------------
    *. Owl Carousel
---------------------------------------------------*/
.owl-theme .owl-dots .owl-dot span {
  background: transparent;
  border-radius: 0;
  border: 1px solid #434343;
  width: 12px;
  height: 12px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.owl-theme .owl-dots .owl-dot.active span {
  background: #434343;
}
.owl-theme .owl-dots .owl-dot:hover span {
  background: #ed5f5e;
  border: 1px solid #ed5f5e;
}
.owl-theme .owl-nav,
.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 40px;
}
.owl-theme .owl-nav [class*=owl-] {
  background: transparent;
  padding: 0px;
  margin: 0 5px;
}
.owl-theme .owl-nav [class*=owl-]:hover {
  background: transparent;
}
.owl-theme .owl-nav [class*=owl-] i {
  font-size: 24px;
  width: 32px;
  height: 32px;
  line-height: 28px;
  text-align: center;
  color: #909090;
  background: #434343;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.owl-theme .owl-nav [class*=owl-] i:hover {
  background: #ed5f5e;
  color: #fff;
}
/*For Darker Area*/
.owl-theme.white-color .owl-dots .owl-dot span {
  border: 1px solid #fff;
}
.owl-theme.white-color .owl-dots .owl-dot.active span {
  background: #fff;
}
.owl-theme.white-color .owl-dots .owl-dot:hover span {
  border: 1px solid #ed5f5e;
  background: #ed5f5e;
}
.owl-theme.white-color .owl-nav [class*=owl-] {
  border-radius: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.owl-theme.white-color .owl-nav [class*=owl-]:hover {
  background: #ed5f5e;
}
.owl-theme.white-color .owl-nav [class*=owl-] i {
  color: #909090;
  background: #fff;
}
.owl-theme.white-color .owl-nav [class*=owl-] i:hover {
  background: #ed5f5e;
  color: #fff;
}
/*--------------------------------------------------
    **. Responsive {should come last}
---------------------------------------------------*/
@media only screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: 991px), only screen and (min--moz-device-pixel-ratio: 2) and (max-width: 991px), only screen and (-o-min-device-pixel-ratio: 2/1) and (max-width: 991px), only screen and (min-device-pixel-ratio: 2) and (max-width: 991px), only screen and (min-resolution: 192dpi) and (max-width: 991px), only screen and (min-resolution: 2dppx) and (max-width: 991px) {
  -webkit-font-smoothing: subpixel-antialiased;
  .btn-main {
    font-size: 8px;
  }
}

body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f0f0f0;
}

.post {
    max-width: 600px;
    margin: 50px auto;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.post-title {
    color: #333;
    font-size: 24px;
    margin-bottom: 10px;
}

.post-content {
    color: #555;
    font-size: 16px;
    line-height: 1.5;
}

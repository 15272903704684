* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.contenedor {
	background:#ccc;
	width:90%;
	max-width:1000px;
	margin:auto;

	/* Flexbox */
	display:flex;
	flex-flow:row wrap;
}

body {
	background:#e9e9e9;
}

header {
	background:#2c3e50;
	width:100%;
	padding:20px;

	/* Flexbox */
	display: flex;
	justify-content:space-between;
	align-items:center;

	flex-direction:row;
	flex-wrap:wrap;
}

header .logo {
	color:#fff;
	font-size:30px;
}

header .logo img {
	width:50px;
	vertical-align: top;
}

header .logo a {
	color:#fff;
	text-decoration: none;
	line-height:50px;
}

header nav {
	width:50%;
	/* Flexbox */

	display:flex;
	flex-wrap:wrap;
	align-items:center;
}

header nav a {
	background:#3c728f;
	color:#fff;
	text-align: center;
	text-decoration: none;
	padding:10px;

	/* Flexbox */
	flex-grow:1;
}

header nav a:hover {
	background:#fff;
}

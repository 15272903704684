/* Estilos del botón */
.boton-flo {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* Estilo adicional para resaltar el botón al pasar el ratón */
.boton-flo:hover {
  background-color: #2980b9;
}